import React from 'react'

import Layout from 'components/Layout'
import { Button } from 'components/System'

const Page = () => (
  <Layout>
    <div className="py-8 md:py-16">
      <div className="container">
        <div className="text-center space-y-4">
          <h1 className="h1">404 — Not found</h1>
          <p>We can't seem to find the page you're looking for.</p>
          <hr className="border-0 h-4" />
          <Button className="mx-auto" to="/">
            Take me back home
          </Button>
        </div>
      </div>
    </div>
  </Layout>
)

export default Page
