import React from 'react'
import clsx from 'clsx'
import { Link } from 'gatsby'

export const Button = ({
  to,
  external,
  fullWidth,
  className,
  onClick,
  type,
  ...props
}) => {
  const TagName = to ? Link : onClick || type === 'submit' ? 'button' : 'a'

  return (
    <TagName
      to={to}
      className={clsx(
        'flex items-center justify-center px-6 py-5 border border-transparent rounded-lg shadow-sm text-2xl font-semibold leading-none text-white bg-indigo-600 transition-all duration-300 transform-gpu hover:scale-105 hover:shadow-xl hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
        fullWidth ? 'max-w-full' : 'max-w-xs',
        className
      )}
      style={{ minWidth: 320 }}
      onClick={onClick}
      type={type}
      {...props}
    />
  )
}
